import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  episode_create,
  episode_delete,
  episode_update,
  all_episode_list,
  episode_audio_file_delete,
  episode_subtitle_delete,
} from "../../../actions/WebSeries/episode";
import { all_season_list } from "../../../actions/WebSeries/season";
import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import {
  all_series_list,
  all_series_name_list,
} from "../../../actions/WebSeries/series";
import { all_language_list } from "../../../actions/Masters/language";
import { all_cast_list } from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";
export default function Episode() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rights = useSelector((state) => state.layout.rights);
  const location = useLocation();
  const tempTableData = {
    ...TableData(),
    deleteRecord: episode_delete,
    updateRecord: episode_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [open, setOpen] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [flag, setFlag] = useState(false);

  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");

  const [result, setResult] = useState(undefined);

  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );

  const formTitle = [
    "Episode Details",
    "Episode Resolution",
    "Age Restrictions & Downloads",
    // role != "Producer" && "Advertisement Details",
  ].filter((e) => e);
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "Episode" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditEpisode");
  }, [location]);

  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const episodes = useSelector((state) => state.webseries.episodes);
  const language = useSelector((state) => state.masters.languages);
  const seasons = useSelector((state) => state.webseries.seasons);
  console.log(seasons, "10000");
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);
    if (episodes?.statuscode != 200) dispatch(all_episode_list(data));
    if (series?.statuscode != 200) dispatch(all_series_name_list(data));
    if (casts?.statuscode != 200) dispatch(all_cast_list());
    if (language?.statuscode != 200) dispatch(all_language_list());
    // dispatch(all_advertisement_list(data));
    if (seasons?.statuscode != 200) dispatch(all_season_list(data));
  }, [user]);
  const series = useSelector((state) => state.webseries.series_name);

  useMemo(() => {
    if (episodes?.statuscode == 200) {
      const temp = tableData;

      temp.tableBody = episodes?.data.map((ele, index) => ({
        ...ele,
        uploaded_by: ele?.series_name?.created_by?.firstName,
        series_name: ele?.series_name?.series_name,
        series_cast: ele?.series_cast.map((cast) => cast.cast_name),
        season: ele?.season_name,
      }));
      setTableData({ ...temp });
    }
  }, [episodes]);

  const casts = useSelector((state) => state.masters.casts);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = series?.data.map((series) => series?.series_name);

      setFormStructure([...temp]);
    }
    if (series?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][0]["options"] = series?.data.map(
        (series) => series?.series_name
      );

      setTableData({ ...tempTableData });
    }
  }, [series, view]);
  // console.log(series,"neeee")
  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      temp[0][0]["disabled"] = true
      temp[0][2]["disabled"] = true
      temp[2][2]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[2][2]["display"] = "none";
      temp[0][0]["disabled"] = false
      temp[0][2]["disabled"] = false


      setFormStructure([...temp]);
    }
  }, [isEdit]);
  useMemo(() => {
    if (seasons?.statuscode == 200) {
      const temp = formStructure;
      temp[0][2]["options"] = seasons?.data
        ?.map(
          (season) => season?.series_name == form?.series_name && season?.season
        )
        .filter((e) => e);

      setFormStructure([...temp]);
    }
    if (seasons?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][1]["options"] = seasons?.data;
      setTableData({ ...tempTableData });
    }
  }, [seasons, form?.series_name, view]);
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][8]["options"] = casts?.data.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts, view]);

  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][6]["display"] = "block";
      temp[0][7]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][6]["display"] = "none";
      temp[0][7]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.publish_type]);

  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    event.preventDefault();

    const tempForm = form;
    tempForm["tempseries_name"] = form?.["series_name"];
    tempForm["tempseries_cast"] = form?.["series_cast"];
    tempForm["tempseason"] = form?.["season"];

    tempForm["series_name"] = series?.data
      .map(
        (option) =>
          form?.["series_name"]?.includes(option.series_name) && option.id
      )
      .filter((e) => e);
    tempForm["series_cast"] = casts?.data
      .map(
        (option) =>
          form?.["series_cast"]?.includes(option.cast_name) && option.id
      )
      .filter((e) => e);
    tempForm["season"] = seasons?.data
      ?.map((option) =>
        form?.["series_name"] == option?.series
          ? form?.["season"]?.includes(option?.season) && option?.id
          : null
      )
      .filter((e) => e);

    tempForm["uploaded_by"] = user?.id;
    setForm({
      ...tempForm,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((structure) =>
      structure.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );
    const temp = tableData;
    if (isEdit) {
      dispatch(episode_update(data));
    } else {
      dispatch(episode_create(data));
    }
    setFlag(true);
  };
  // console.log(episodes, "654654");
  useMemo(() => {
    if (message?.statuscode == 200 && flag) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setFormStructure([...tempFormStruct]);
      setForm({
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      });
      setFlag(false);

      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_episode_list(data));
        navigate("/Episode/Episode/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["series_name"] = form?.tempseries_name;
      tempForm["series_cast"] = form?.tempseries_cast;
      tempForm["season"] = form?.tempseason;
      tempForm["episode_thumbnail"] = form?.tempepisode_thumbnail;
      tempForm["episode_poster"] = form?.tempepisode_poster;
      setForm({ ...tempForm });
    }
  }, [message]);

  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, episode_duration: hDisplay + mDisplay + sDisplay });
  };

  const handleClose = () => {
    setOpenAdError(false);
  };
  useMemo(() => {
    var temp = form;

    if (isEdit) {
      var flag = true;
      for (var i = 0; i < episodes?.data.length; i++) {
        if (episodes?.data[i]?.id == form?.id) {
          if (
            episodes?.data[i]?.series_name?.series_name == form?.series_name
          ) {
            flag = false;
          }
          break;
        }
      }

      if (flag) {
        temp = {
          subtitles: "Inactive",
          episode_is_for18Plus: "No",
          allow_download: "Yes",
          audio_language: "Inactive",
        };
        temp["id"] = form?.id;
        temp["series_name"] = form?.series_name;
      }
    } else {
      temp = {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      };
      temp["series_name"] = form?.series_name;
      // temp["season"]=form?.season
    }

    series?.data.filter((series) => {
      if (form?.series_name == series?.series_name)
        temp["series_type"] = series?.series_type;
    });

    setForm({ ...temp });
  }, [form?.series_name]);

  return (
    <>
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.episodeMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Episode/CreateEpisode/"}
        view_all={"/Episode/Episode/"}
        form={form}
        access={rights?.["TV Shows"]?.["create"] == "true"}
        export_excel={
          <Export
            fileName={"TV Shows"}
            access={rights?.["TV Shows"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Episode/EditEpisode/"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
