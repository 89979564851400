

export const MESSAGE = 'MESSAGE';
export const LOGGEDIN = 'LOGGEDIN';
export const RIGHTS = 'RIGHTS';
export const ROLE = 'ROLE';
export const ADVERTISER = 'ADVERTISER';
export const DISTRIBUTOR = 'DISTRIBUTOR';
export const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";
// export const SUBADMIN = "SUBADMIN"
export const PROFILE = 'PROFILE';



// Masters
export const CATEGORIES = 'CATEGORIES'
export const SUBCATEGORIES= 'SUBCATEGORIES'
export const CASTS= 'CASTS'
export const GENRE= 'GENRE'
export const LANGUAGES= 'LANGUAGES'
export const DISTRIBUTORS= 'DISTRIBUTORS'
export const SUBADMINS= 'SUBADMINS'
export const MODULES= 'MODULES'
export const AVATARS = "AVATARS"
export const COUNTRIES = "COUNTRIES"
export const ADVISORY = "ADVISORY"


// Subscription
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'

//Reports
export const REPORTS = "REPORTS" 

//Bank Details
export const BANKDETAILS = "BANKDETAILS"
export const BANKDETAILSLIST = "BANKDETAILSLIST"


// Advertisement
export const ADVERTISEMENTS = "ADVERTISEMENTS"
export const ADPAYMENT="ADPAYMENT"
export const ADPAY = "ADPAY"
export const SETAD = "SETAD"
export const ADDELETE = "ADDELETE"

// Customer
export const CUSTOMERS = "CUSTOMERS"
export const CUSTOMER = "CUSTOMER"
export const CUSTOMER_NAME = "CUSTOMER_NAME"

// Live Streaming
export const TV_CATEGORIES = "TV_CATEGORIES"
export const TV_CHANNELS = "TV_CHANNELS"

//Current
export const CURRENTTIME = "CURRENTTIME" 

// Merchandise
export const COUPONS = "COUPONS"
export const COUPONHISTORY = "COUPONHISTORY"
export const NOTIFICATIONS = "NOTIFICATIONS"
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT"
export const NOTIFICATION_READ = "NOTIFICATION_READ"
export const SLIDER_BANNERS = "SLIDER_BANNERS"

// Movie
export const MOVIES = "MOVIES"
export const MOVIE = "MOVIE"
export const UPCOMING_MOVIES = "UPCOMING_MOVIES"
export const MOVIE_WATCH_USER = "MOVIE_WATCH_USER"
export const MOVIE123 = "MOVIE123"
export const SUBTITLEDELETE = "SUBTITLEDELETE"
export const AUDIOFILEDELETE = "AUDIOFILEDELETE"
export const MOVIENAME = "MOVIENAME"

//Analytics
export const HIGHEST_WATCHED_MOVIES_GRAPH ="HIGHEST_WATCHED_MOVIES_GRAPH"
export const LOWEST_WATCHED_MOVIES = "LOWEST_WATCHED_MOVIES"
export const HIGHEST_SEARCHED_MOVIES_GRAPH = "HIGHEST_SEARCHED_MOVIES_GRAPH"
export const CURRENTLY_LOGGED_IN_USERS = "CURRENTLY_LOGGED_IN_USERS"
export const CURRENTLY_LOGGED_IN_USERS_BY_STATE_GRAPH = "CURRENTLY_LOGGED_IN_USERS_BY_STATE_GRAPH"
export const CURRENTLY_WATCHING_USERS = "CURRENTLY_WATCHING_USERS"
export const DEVICE_USED_FOR_WATCHING = "DEVICE_USED_FOR_WATCHING"
export const AREA_WISE_AD_VIEW = "AREA_WISE_AD_VIEW"
export const ADVERTISEMENT_VIEW_GRAPH = "ADVERTISEMENT_VIEW_GRAPH"


// Report
export const PPV_CUSTOMERS = "PPV_CUSTOMERS"
export const REGISTERED_CUSTOMERS = "REGISTERED_CUSTOMERS"
export const SUBSCRIBED_CUSTOMERS = "SUBSCRIBED_CUSTOMERS"

// Transaction
export const TRANSACTIONS = "TRANSACTIONS"

// Watchhours
export const WATCHHOURS = "WATCHHOURS"

//Promotion

export const PROMOTION = "PROMOTION"

// Web Series
export const EPISODES = "EPISODES"
export const EPISODE = "EPISODE"
export const SERIES = "SERIES"
export const SEASONS = "SEASONS"
export const EPISODE_WATCH_USER = "EPISODE_WATCH_USER"
export const EPSUBTITLEDELETE = "EPSUBTITLEDELETE"
export const EPAUDIOFILEDELETE = "EPAUDIOFILEDELETE"
export const SERIESDETAILS = "SERIESDETAILS"
export const SERIES_NAME = "SERIES_NAME"
// Produce Form

export const PRODUCERFORMDATA = "PRODUCERFORMDATA"
export const PRODUCERFORMDETAILS = "PRODUCERFORMDETAILS"

// Setting
export const ABOUTUS = "ABOUTUS"
export const PRIVACYPOLICY = "PRIVACYPOLICY"
export const TERMSCONDITIONS = "TERMSCONDITIONS"
export const REFUNDPOLICY = "REFUNDPOLICY"
export const APPSETTING = "APPSETTING"
export const LOGS = "LOGS"
export const LOGSDEC = "LOGSDEC"

// Dashboard
export const TOTALUSERS = "TOTALUSERS"
export const DASHBOARDCOUNT = "DASHBOARDCOUNT"
export const DASHBOARDMOVIES = "DASHBOARDMOVIES"
export const TOTALSUBSCRIBEUSER = "TOTALSUBSCRIBEUSER"
export const MONTHLYREVENUE = "MONTHLYREVENUE"
export const TOTALREVENUE = "TOTALREVENUE"
export const TV_SHOW_DASHBOARD = "TV_SHOW_DASHBOARD"
export const RECENT_SUBSCRIBER_DASHBOARD = "RECENT_SUBSCRIBER_DASHBOARD"
export const DAILY_REVENUE = "DAILY_REVENUE"
export const MAX_LOGIN_DURATION = "MAX_LOGIN_DURATION"
export const RENEWAL = "RENEWAL"



// Complaint
export const COMPLAINTS = "COMPLAINTS"

//Advertisors
export const ADVERTISERS = "ADVERTISERS"
export const ADPAYMENTLIST = "ADPAYMENTLIST"
export const ADFORMDETAILS = "ADFORMDETAILS"