import React from "react";
import AddIcon from "@mui/icons-material/Add";
import SRTFile from "./../../../images/SRTFile.png";
export const FormStructure = (isEdit) => {
  return [
    [
      {
        id: "1",
        type: "select",
        title: "Select Series",
        name: "series_name",
        options: ["Series", "Series", "Series"],
        required: true,
        // disabled: isEdit ? "true" : "false",
      },
      {
        id: "2",
        type: "inputBox",
        title: "Show Access",
        name: "series_type",
        disabled: "true",
      },
      {
        id: "3",
        type: "select",
        title: "Select Season",
        name: "season",
        options: [],
        required: true,
      },
      {
        id: "4",
        type: "select",
        title: "Select Episode No",
        name: "episode_number",
        options: [
          "Episode 1",
          "Episode 2",
          "Episode 3",
          "Episode 4",
          "Episode 5",
          "Episode 6",
          "Episode 7",
          "Episode 8",
          "Episode 9",
          "Episode 10",
          "Episode 11",
          "Episode 12",
          "Episode 13",
          "Episode 14",
          "Episode 15",
        ],
        required: true,
      },
      // {
      // 	id: "3",
      // 	type: "select",
      // 	title: "Access Type",
      // 	name: "access",
      // 	options: ["Free", "Pay Per View", "Premium"],
      // 	required:true
      // },
      {
        id: "5",
        type: "inputBox",
        title: "Episode Title",
        name: "episode_title",
        maxLength: 50,
        regex: /^[a-zA-Z0-9\s\&\"\-\?\,]+$/,
        required: true,
      },
      // {
      // 	id: "4",
      // 	type: "inputBox",
      // 	title: "Episode Number",
      // 	name: "episode_number",
      // 	variant:"Number",
      // 	required:true
      // },
      {
        id: "6",
        type: "select",
        title: "Publish Type",
        name: "publish_type",
        options: ["Now", "Later"],
        required: true,
      },
      {
        id: "7",
        type: "inputBox",
        variant: "date",
        title: "Release Date",
        default:new Date().toISOString().split('T')[0],
        name: "release_date",
        min: new Date().toISOString().split("T")[0],
        required: true,
        display: "none",
      },
      {
        id: "8",
        type: "inputBox",
        variant: "time",
        title: "Release Time",
        name: "publish_time",
        required: true,
        display: "none",
      },

      {
        id: "9",
        type: "select_multiple",
        title: "Select Cast",
        name: "series_cast",
        maxSelections: "3",
        errorText: "You can select max 3 cast at a time",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "10",
        type: "description",
        title: "Description",
        name: "episode_description",
        required: true,
      },
    ],
    [
      {
        id: "1",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "M3U8 URL ( Resolution: 1080p)",
        endsWith: ".m3u8",
        errorText: "File extension should be .m3u8 only",
        name: "episode_link",
        required: true,
      },
      {
        id: "5",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Mp4 or MKV URL",
        endsWith: ".mp4",
        endsWith1: ".mkv",
        errorText: "File extension should be .mp4 or .mkv only",
        name: "episodeMp4_URL",
        required: true,
      },
      {
        id: "6",
        type: "inputBox",
        title: "Episode duration",
        name: "episode_duration",
        disabled: true,
      },
        {
        id: "7",
        type: "duration",
        title: "Skip Start",
        name: "skip_start",
        size:"2",
        // disabled: true,
        // required: true
    },
    {
        id: "8",
        type: "duration",
        title: "Skip End",
        name: "skip_end",
        size:"2",
        // disabled: true,
        // required: true
    },
    {
      id: "9",
      type: "duration",
      title: "Episode End",
      name: "episode_end",
      size:"2",
      // disabled: true,
      // required: true
  },
      {
        id: "11",
        type: "image",
        title: "Browse Poster",
        subtitle: "(Resolution : 1280 x 720)",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        size: "4",
        name: "episode_poster",
        required: true,
      },
    ],
    [

      {
        id: "21",
        type: "toggle",
        title: "Is This Video 18+ ?",
        name: "episode_is_for18Plus",
        size: "3",
        default: "No",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "22",
        type: "toggle",
        title: "Allow Download ?",
        name: "allow_download",
        size: "3",
        default: "Yes",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "23",
        type: "toggle",
        title: "Status",
        display: "block",
        name: "status",
        default: "Active",
  			display: "none",

        size: "3",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
    ]
  ];
};
