
import { MESSAGE,SERIES,SERIESDETAILS ,SERIES_NAME } from '../../constants/actionTypes';
import * as api from '../../api/index.js';


export const series_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.series_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const series_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.series_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const series_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.series_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_series_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_series_list(formData);
      
      dispatch({ type: SERIES, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const series_details_admin = (formData) => async (dispatch) => {
    try {
      const { data } = await api.series_details_admin(formData);
      dispatch({type: SERIESDETAILS , payload:data });
    } catch(error){
      console.log(error)
    }
  }
  
  export const all_series_name_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_series_name_list(formData);
      dispatch({type: SERIES_NAME , payload:data });
    } catch(error){
      console.log(error)
    }
  }
  