import React from "react"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from "@mui/icons-material/Remove";

import SRTFile from "./../../../images/SRTFile.png"
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
    const role = useSelector((state) => state.layout.role)

    return [
        [
            {
                id: "1",
                type: "select",
                title: "Video Category",
                name: "movie_category",
                options: [],
                required: true
            },
            {
                id: "2",
                type: "select",
                title: "Sub Category",
                name: "movie_subcategory",
                options: [],
                required: true
            }
            ,
            {
                id: "3",
                type: "select",
                title: "Access",
                name: "movie_access",
                // disabled:isEdit ? true : false,
                options: ["FREE", "TVOD", "SVOD"],
                required: true
            }
            ,
            {
                id: "4",
                type: "inputBox",
                title: "Name",
                name: "movie_name",
                // maxLength: "100",
                maxLength: "50",
                showLimit:true,
                regex: /^[a-zA-Z0-9\s\&]+$/,
                isCapitalise: true,
                required: true
            }
            ,
            {
                id: "5",
                type: "select",
                title: "Language",
                name: "movie_language",
                options: ["English", "Hindi", "Gujarati"],
                required: true
            }
            ,
            {
                id: "6",
                type: "select_multiple",
                title: "Select Genre",
                name: "movie_genre",
                maxSelections: "3",
                errorText: "You can select max 3 genre at a time",
                options: [],
                required: true

            },

            {
                id: "7",
                type: "select_multiple",
                title: "Select Cast",
                name: "movie_cast",
                maxSelections: "7",
                errorText: "You can select max 7 cast at a time",
                options: [],
                // required: true

            },
            {
                id: "8",
                type: "select",
                title: "Publish Type",
                name: "publish_type",
                options: ["Today", "Later"],
                required: true

            },
            {
                id: "9",
                type: "inputBox",
                variant: "date",
                title: "Publish Date",
                min: new Date().toISOString().split('T')[0],
                name: "release_date",
                default:new Date().toISOString().split('T')[0],
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                variant: "time",
                title: "Publish Time",
                default:new Date().toISOString().split('T')[1],
                name: "publish_time",
                display: "none",
                required: true
            },
            
            {
                id: "11",
                type: "inputBox",
                title: `Rental Price`,
                name: "rental_price",
                regex: /^[0-9\.]+$/,
                maxLength: "6",
                display: "none",
                required: true
            },
            {
                id: "12",
                type: "inputBox",
                variant: "number",
                title: "Tax (18.5%)",
                name: "tax",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "13",
                type: "inputBox",
                title: "Streaming Fees",
                name: "streaming_fees",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "14",
                type: "inputBox",
                variant: "number",
                title: "Amount After Tax",
                name: "amount_received",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "15",
                type: "inputBox",
                title: "Available Till (Days)",
                name: "available_for_days",
                regex: /^[0-9]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "16",
                type: "toggle",
                title: "Available for Basic Plan",
                name: "available_for_basic_plan",
                default: "Yes",
                display: "none",

                size: "3",
                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }],


            },
            {
                id: "17",
                type: "description",
                title: "Description",
                name: "movie_description",
                required: true
            },
        ],
        [
            {
                id: "11",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 1080p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movie_link",
                required: true
            },
            {
                id: "12",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Upload Movie Mp4 or MKV URL",
                endsWith: ".mp4",
                endsWith1: ".mkv",
                errorText: "File extension should be .mp4 or .mkv only",
                name: "movieMp4_URL",
                required: true
            },
            {
                id: "13",
                type: "inputBox",
                title: "Duration",
                name: "movie_duration",
                disabled: true,
                required: true
            },

            {
                id: "14",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Trailer URL ( .m3u8 or .mp4)",
                endsWith: ".m3u8",
                endsWith1: ".mp4",
                errorText: "File extension should be .m3u8 or .mp4 only",
                name: "movie_trailer",
                required: true
            },
            {
                id: "15",
                type: "inputBox",
                title: "Trailer Duration",
                name: "movie_trailer_duration",
                disabled: true,
                required: true
            },
            // {
            //     id: "16",
            //     type: isEdit ? "lockedInput" : "inputBox",
            //     title: "Free Preview URL",
            //     endsWith: ".m3u8",
            //     endsWith1: ".mp4",
            //     errorText: "File extension should be .m3u8 or .mp4 only",
            //     name: "free_preview",
            //     // required: true,
            //   },
            //   {
            //     id: "17",
            //     type: "duration",
            //     title: "Skip Start",
            //     name: "skip_start",
            //     size:"2",
            //     // disabled: true,
            //     // required: true
            // },
           
            // {
            //     id: "18",
            //     type: "duration",
            //     title: "Skip End",
            //     name: "skip_end",
            //     size:"2",
            //     // disabled: true,
            //     // required: true
            // },
            // {
            //     id: "19",
            //     type: "duration",
            //     title: "Movie End",
            //     name: "movie_end",
            //     size:"2",
            //     // disabled: true,
            //     // required: true
            // },
            {
                id: "110",
                type: isEdit ? "lockedInput" : "inputBox",
                variant: "number",
                title: "Set Position",
                name: "set_position",
                min: "0",
                max: "99",
                size:"6",
                required: true
            },

            {
                id: "111",
                type: "image",
                subtitle: "(Resolution : 1920px x 1080px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: 'Browse Poster',
                name: "movie_poster",
                size: 3,
                required: true
            },
            {
                id: "112",
                type: "image",
                title: "Browse Thumbnail",
                subtitle: "(Resolution : 1920px x 720px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                name: "movie_thumbnail",
                required: true,
                size: 3,

            }, 
            {
                id: "113",
                type: "image",
                subtitle: "(Resolution : 1080px x 1080px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: "Movie Scene",
                name: "movie_scene",
                size: 3,
                required: true,
              },

        ],
      
        [


            {
                id: "21",
                type: "toggle",
                title: "Is This Video 18+ ?",
                name: "movie_is_for18Plus",
                default: "No",
                size: "3",

                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }]

            },
            {
                id: "22",
                type: "toggle",
                title: "Allow Download ?",
                name: "allow_download",
                default: "Yes",
                size: "3",
                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }],


            },
            {
                id: "23",
                type: "toggle",
                title: "Status",
                name: "status",

                default: "Active",
                display : "none",
                size: "3",
                options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],


            },

          
           
        ],

    ]
}