import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  sliderbanner_create,
  sliderbanner_delete,
  sliderbanner_update,
  all_sliderbanner_list,
} from "../../actions/sliderbanner";
import {  all_movie_name_list } from "../../actions/Movie/movie";
import { all_series_name_list } from "../../actions/WebSeries/series";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Reload from "../utils/Reload";
export default function SliderBanner() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const rights = useSelector((state) => state.layout.rights);
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[2];
  // useEffect(() => {
  // 	setView(path != "SliderBanner" ? "create_new" : "view_all");
  // 	setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
  // 	setIsEdit(path == "EditSliderBanner");
  // }, [location]);
  const tempTableData = {
    tableTitle: "Slider Banner",
    deleteRecord: sliderbanner_delete,
    updateRecord: sliderbanner_update,
    deleteAccess: rights?.["Slider Banner"]?.["delete"] == "true",
    customisedStatusUpdateMessage: true,
    onDeleteText: "Are you sure want to delete Slider Banner?",
    onActiveText: "Are you Sure want to Activate Slider Banner?",
    onInactiveText: "Are you Sure want to Inactivate Slider Banner?",
    tableHead: [
      {
        id: "videoType",
        label: "Video Type",
      },
      {
        id: "name",
        label: "Name",
      },
      // {
      //   id: "slider_type",
      //   label: "Slider Type",
      // },
      {
        id: "app_banner",
        label: "App View",
        isImage: true,
      },
      // {
      //   id: "web_banner",
      //   label: "Web View",
      //   isImage: true,
      // },

      {
        id: "set_sequence",
        label: "Sequence",
        align: "center",
      },
      {
        id: "expiry_date1",
        label: "Expired on",
        isSpecial: true,
        align: "left",
      },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        access: rights?.["Slider Banner"]?.["edit"] == "true",
      },
    ],
    tableBody: [
      {
        id: 0,
        movie_name: "Movie",
        slider_type: "Landscape",
        app_image: "Landscape",
        web_image: "Landscape",
        videoType: "Landscape",
        sequence: "2",
        status: "Active",
        edit: 0,
      },
    ],
    filterColumn: [
      // {
      //   id: "2",
      //   title: "Video Type",
      //   name: "videoType",
      //   options: ["Movie", "Series"],
      // },
      // {
      //   id: "2",
      //   title: "Slider Type",
      //   name: "slider_type",
      //   options: ["Image", "Video"],
      // },
    ],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  useEffect(() => {
    setView(path != "SliderBanner" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        ...form,
        set_sequence: tableData?.tableBody?.length + 1,
      }
    );
    setIsEdit(path == "EditSliderBanner");
  }, [location]);
  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Video Type",
      name: "videoType",
      disabled: isEdit ? true : false,
      options: ["Movie"
        , "Series"
      ],
      required: true,
    },

    {
      id: "2",
      type: "select",
      title: "Select ",
      options: [],
      name: "movie_name",
      display: "none",
      required: true,
    },

    {
      id: "3",
      type: "lockedInput",
      variant: "number",
      title: "Set Sequence",
      name: "set_sequence",
      min: "1",
      max: "99",
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      variant: "date",
      title: "Expired on",
      default:" ",
      min: new Date().toISOString().split("T")[0],
      name: "expiry_date",
      required: true,
    },
    {
      id: "5",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display:"none",
      size: "6",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    // {
    //   id: "6",
    //   type: "image",
    //   title: "Browse Web Banner",
    //   subtitle: "(Resolution : 1920 px x 1080px) *",
    //   subsubtitle: "Max File Size 1MB",
    //   subsubsubtitle: "Support only JPG,PNG,JPEG",
    //   name: "web_banner",

    //   required: true,
    // },
    {
      id: "7",
      type: "image",
      title: "Browse App Banner",
      subtitle: "(Resolution : 1080px x 1080px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "app_banner",
      required: true,
    },

    {
      id: "8",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);

  const sliderbanners = useSelector(
    (state) => state.merchandise.slider_banners
  );
  useEffect(() => {
    dispatch(all_sliderbanner_list());
  }, []);
  const movies = useSelector((state) => state?.movies?.movie_name);
  const series = useSelector((state) => state?.webseries?.series_name);
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);

    dispatch(all_series_name_list(data));
    dispatch(all_movie_name_list(data));

  }, [user]);

  useMemo(() => {
    if (sliderbanners?.statuscode == 200) {
      const temp = tableData;
      const today = new Date();

      temp.tableBody = sliderbanners?.data?.map((ele) => ({
      ...ele,
      name : ele?.movie ? ele?.movie?.movie_name : ele?.series?.series_name,
      movie :ele?.movie?.movie_name,
      series : ele?.series?.series_name,
      expiry_date1:
      new Date(ele?.expiry_date) > today ? (
        <p>{ele?.expiry_date}</p>
      ) : (
        <p style={{ color: "red" }}>Expired</p>
      ),
    edit1: !(today <= new Date(ele?.expiry_date)),
      }));

      setTableData({ ...temp });
      setForm({ ...form, set_sequence: tableData.tableBody.length + 1 });
    }
  }, [sliderbanners]);
  // console.log(sliderbanners, "newwwww");

  const formTitle = isEdit ? "Edit Slider" : "Add Slider";
  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      temp[0]["type"] = "inputBox";
      temp[0]["disabled"] = true;
      temp[4]["display"] = "block"
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;

      temp[0]["type"] = "select";
      temp[0]["disabled"] = false;
      temp[4]["display"] = "none"

      setFormStructure([...temp]);
    }
  }, [isEdit]);
  useMemo(() => {
    if (form?.videoType == "Movie") {
      const temp = formStructure;
      temp[1]["display"] = "block";
      temp[1]["title"] = "Select Movie";
      temp[1]["name"] = "movie";
      temp[1]["options"] = movies?.data?.map((ele) => ele?.movie_name);
      setFormStructure([...temp]);
    } else if (form?.videoType == "Series") {
      const temp = formStructure;
      temp[1]["display"] = "block";
      temp[1]["title"] = "Select Series";
      temp[1]["name"] = "series";
      temp[1]["options"] = series?.data?.map((ele) => ele?.series_name);
      setFormStructure([...temp]);
    } 
    else {
      const temp = formStructure;
      temp[1]["display"] = "none";
      setFormStructure([...temp]);
    }
 
  }, [form?.videoType]);
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_form = form;
    temp_form["tempmovie"] = form?.["movie"];
    temp_form["tempseries"] = form?.["series"];
    temp_form["movie"] = movies?.data
      .map(
        (option) => form?.["movie"]?.includes(option?.movie_name) && option?.id
      )
      .filter((e) => e)[0];
    temp_form["series"] = series?.data
      .map(
        (option) =>
          form?.["series"]?.includes(option?.series_name) && option?.id
      )
      .filter((e) => e)[0];

    setForm({ ...temp_form });

    Object.keys(form).map(
      (key) => key != "expired_on" && data.append(key, form?.[key])
    );
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(sliderbanner_update(data));
    } else {
      dispatch(sliderbanner_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_sliderbanner_list());
        navigate("/SliderBanner/SliderBanner/", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
    } else {
      const tempForm = form;

      tempForm["movie"] = form?.tempmovie;
      tempForm["series"] = form?.tempseries;
      tempForm["web_banner"] = form?.tempweb_banner;
      tempForm["app_banner"] = form?.tempapp_banner;

      setForm({ ...tempForm });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/SliderBanner/CreateSliderBanner"}
        view_all={"/SliderBanner/SliderBanner"}
        form={form}
        reload={<Reload isClubed={true} />}
        access={rights?.["Slider Banner"]?.["create"] == "true"}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/SliderBanner/EditSliderBanner"}
        />
      )}
    </>
  );
}
