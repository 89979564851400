import React from "react";
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
  const role = useSelector((state) => state.layout.role);
  return [
  
    {
      id: "1",
      type: "inputBox",
      title: "Series Name",
      name: "series_name",
      maxLength: "25",
      regex: /^[a-zA-Z0-9\s\&]+$/,
      isCapitalise: true,
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Series Access",
      name: "series_type",
      options: ["FREE", "TVOD", "SVOD"],
      required: true,
    },
    {
      id: "3",
      type: "select",
      title: "Language",
      name: "language",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "4",
      type: "select_multiple",
      title: "Select Genre",
      name: "genre",
      maxSelections: "3",
      errorText: "You can select max 3 genre at a time",
      options: ["Portrait", "Landscape"],
      required: true,
    },

    {
      id: "5",
      type: "inputBox",
      title: "Rental Price",
      name: "rental_price",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      display: "none",
    },
    {
      id: "6",
      type: "inputBox",
      title: "Available For Days",
      name: "available_for_days",
      regex: /^[0-9\.]/,
      maxLength: "2",
      display: "none",
    },
    {
      id: "7",
      type: "inputBox",
      title: "Trailer URL ( .mp4 or .mkv )",
      endsWith: ".mp4",
      endsWith1: ".mkv",
      errorText: "File extension should be .mp4 or .mkv only",
      name: "series_trailer",
      required: true,
    },
    
    {
      id: "8",
      type: "toggle",
      title: "First Episode Free",
      name: "first_episode_free",
      size: "3",
      options: [
        { value: "Yes", color: "success" },
        { value: "No", color: "danger" },
      ],
      required: true,
    },
    {
      id: "9",
      type: "toggle",
      title: "Status",
      display: "block",
      name: "status",
      default: "Active",
      display: isEdit ? "block" : "none",
      size: "3",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "10",
      type: "description",
      title: "Description",
      name: "series_description",
      required: true,
    },
    {
      id: "11",
      type: "image",
      title: "Browse Thumbnail",
      subtitle: "(Resolution : 1920px x 720px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "series_thumbnail",
      size: "3",

      required: true,
    },
    {
      id: "12",
      type: "image",
      title: "Browse Poster",
      subtitle: "(Resolution : 1920px x 1080px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "3",
      name: "series_poster",
      required: true,
    },
    {
      id: "13",
      type: "image",
      title: "Series Scene",
      subtitle: "(Resolution : 1080px x 1080px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "3",
      name: "series_scene",
      required: true,
    },
    {
      id: "14",
      type: "button",
      title: "Create",
    },
  ];
};
