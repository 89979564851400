import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  movie_create,
  movie_delete,
  movie_update,
  all_movie_list,
  subtitle_delete,
  audio_file_delete,
} from "../../../actions/Movie/movie";
import { all_distributor_list } from "../../../actions/distributor";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Export from "../../utils/Export";
import Import from "../../utils/Import";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import EnlargedView from "../../utils/EnlargedView";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Reload from "../../utils/Reload";
import LoadingGIF from "../../../images/Loading.gif";
import { all_content_advisory_list } from "../../../actions/Masters/contentadvisory";

export default function Movie() {
  const user = useSelector((state) => state.layout.profile);

  const distributor = useSelector((state) => state.layout.distributor);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [openAdError, setOpenAdError] = useState(false);
  const [flag, setFlag] = useState(false);
  // const [adPosition, setAdPosition] = useState();
  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [result, setResult] = useState(undefined);
  const rights = useSelector((state) => state.layout.rights);
  const [content, setPopupContent] = useState("");
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);

  const tempTableData = {
    ...TableData(),
    deleteRecord: movie_delete,
    // disableDelete: role !== "Producer" ? false : true,

    onDeleteText: [
      "Are you sure want to delete this video ?",
      "The Customer Will not be able to see this video once you delete it.",
    ],
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure want to Activate the video ?",
    onInactiveText: [
      "Are you sure want to Inactivate the video ?",
      "The Customer Will not be able to see this video once you Inactivate it.",
    ],
    updateRecord: movie_update,
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  // console.log(location ,"951159")
  const tempFormStruct = FormStructure(isEdit);
  const [open1, setOpen1] = useState(false);

  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const [formTitle, setFormTitle] = useState(
    [
      "Movie Details",
      "Movie Resolutions",

      "Age Restrictions & Downloads",
      // role != "Producer" && "Advertisement Details",
    ].filter((e) => e)
  );
  const [formTitleNew, setFormTitleNew] = useState(
    [
      "Movie Details",
      "Movie Resolutions",

      "Age Restrictions & Downloads",
      // role != "Producer" && `Advertisement Details`,
    ].filter((e) => e)
  );

  const categories = useSelector((state) => state.masters.categories);
  const subcategories = useSelector((state) => state.masters.subcategories);
  const casts = useSelector((state) => state.masters.casts);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);

  const path = location?.pathname.split("/")[2];
  // useEffect(() => {
  //   setTimeout(() => {
  //     setView(path != "Movie" ? "create_new" : "view_all");
  //   }, 200);
  //   console.log(location?.state?.form , "FormTesting" )
  //   setForm(
  //     (location?.state?.form && JSON.parse(location?.state?.form)) || {
  //       set_position: tableData.tableBody.length + 1,
  //       subtitles: "Inactive",
  //       movie_is_for18Plus: "No",
  //       allow_download: "Yes",
  //       status: "Active",
  //       audio_language: "Inactive",
  //     }
  //   );
  //   setIsEdit(path == "EditMovie");
  // }, [location]);
  useEffect(() => {
    setTimeout(() => {
      setView(path !== "Movie" ? "create_new" : "view_all");
    }, 200);

    // console.log(location?.state?.form, "FormTesting");  // Log object separately
    // console.log(JSON.stringify(location?.state?.form), "Form as JSON");  // Log as JSON string if needed

    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        set_position: tableData.tableBody.length + 1,
        subtitles: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
        audio_language: "Inactive",
      }
    );

    setIsEdit(path === "EditMovie");
  }, [location]);

  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const movies = useSelector((state) => state.movies.movies);
  useMemo(() => {
    if (user?.id) {
      const data = new FormData();
      data.append("id", user?.id);
      if (movies?.statuscode != 200) dispatch(all_movie_list(data));
      if (subcategories?.statuscode != 200) dispatch(all_subcategory_list());
      if (categories?.statuscode != 200) dispatch(all_category_list());
      if (casts?.statuscode != 200) dispatch(all_cast_list());
      if (genre?.statuscode != 200) dispatch(all_genre_list());
      if (language?.statuscode != 200) dispatch(all_language_list());
    }
  }, [user]);
  // console.log(form,
  //   "SkyTESTING"
  // )
  useMemo(() => {
    if (movies?.statuscode == 200) {
      // console.log(movies,"20/12/2023")

      const temp = tableData;
      temp.tableBody = movies?.data.map((value, index) => ({
        ...value,
        movie_genre: value?.movie_genre.map((genre) => genre.genre_title),
        movie_cast: value?.movie_cast.map((cast) => cast.cast_name),
        uploaded_by: value?.created_by?.firstName,
        movie_distributor: value?.movie_distributor?.name,
        movie_suggestion: value?.movie_suggestion?.movie_name,
        company_name: value?.movie_distributor?.company_name,
        movie_access1:
          value?.movie_access === "FREE" ? (
            <span style={{ color: "var(--successColor)" }}>
              {value?.movie_access}
            </span>
          ) : value?.movie_access === "TVOD" ? (
            <span style={{ color: "var(--dangerColor)" }}>
              {value?.movie_access}
            </span>
          ) : (
            <span style={{ color: "var(--warningColor)" }}>
              {value?.movie_access}
            </span>
          ),
        released_status:
          new Date(value?.release_date) > new Date()
            ? "Upcoming"
            : value?.release_date,
        color: new Date(value?.release_date) > new Date() && "rgb(27,27,27)",
      }));
      temp.filterColumn[4]["options"] = [
        ...new Set(movies?.data.map((value) => value?.created_by?.firstName)),
      ];
      setTableData({ ...temp });
      setForm({ ...form, set_position: temp.tableBody.length + 1 });
    }
  }, [movies]);

  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][8]["display"] = "block";
      temp[0][9]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][8]["display"] = "none";
      temp[0][9]["display"] = "none";
      setFormStructure([...temp]);
    }
    if (form?.movie_access == "TVOD") {
      const temp = formStructure;
      temp[0][10]["display"] = "block";
      temp[0][11]["display"] = "block";
      temp[0][12]["display"] = "block";
      temp[0][13]["display"] = "block";
      temp[0][14]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][10]["display"] = "none";
      temp[0][11]["display"] = "none";
      temp[0][12]["display"] = "none";
      temp[0][13]["display"] = "none";
      temp[0][14]["display"] = "none";

      setFormStructure([...temp]);
    }
    if (form?.movie_access == "SVOD") {
      const temp = formStructure;
      temp[0][15]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][15]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.publish_type, form?.movie_access]);
  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      temp[2][2]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[2][2]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [isEdit]);

  useMemo(() => {
    if (form?.rental_price != "") {
      setForm({
        ...form,
        amount_received: (
          form?.rental_price -
          form?.streaming_fees -
          Number(form?.rental_price) * 0.185
        ).toFixed(2),
        tax: (Number(form?.rental_price) * 0.185).toFixed(2),
      });
    }
  }, [form?.rental_price, form?.streaming_fees]);
  useMemo(() => {
    if (form?.amount_received < 0) {
      setOpenAdError(true);
      setPopupContent("Your receivable amount goes into Minus(-)");
    }
  }, [form?.amount_received]);

  useMemo(() => {
    if (categories?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tempFilter });
    }
  }, [categories, view]);

  useMemo(() => {
    if (subcategories?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      const tempFilter = tableData;
      tempFilter["filterColumn"][3]["options"] = subcategories?.data;

      if (form?.movie_category != undefined) {
        temp[0][1]["options"] = subcategories?.data
          .map(
            (subcategory) =>
              subcategory?.category_name == form?.movie_category &&
              subcategory?.subcategory_name
          )
          .filter((e) => e);
        tempForm.movie_subcategory = temp[0][1]["options"].includes(
          form?.movie_subcategory
        )
          ? form?.movie_subcategory
          : "";
        // tempFilter["filterColumn"][3]["options"]=subcategories?.data.map((subcategory)=>(subcategory?.category_name==form?.movie_category&&subcategory?.subcategory_name))
      } else {
        temp[0][1]["options"] = subcategories?.data.map(
          (subcategory) => subcategory?.subcategory_name
        );
      }
      setFormStructure([...temp]);
      setForm({ ...tempForm });
      setTableData({ ...tempFilter });
    }
  }, [subcategories, form?.movie_category, view]);
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][6]["options"] = casts?.data.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts, view]);

  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][5]["options"] = genre?.data.map((genre) => genre?.genre_title);

      setFormStructure([...temp]);
    }
  }, [genre, view]);
  useEffect(() => {
    if (form?.movie_duration != null || undefined) {
      setFormTitle(
        [
          "Movie Details",
          "Movie Resolutions",

          "Age Restrictions & Downloads",
          // role != "Producer" &&
          //   `Advertisement Details (Video duration:- ${form?.movie_duration})`,
        ].filter((e) => e)
      );
    }
  }, [form?.movie_duration]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][4]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempFilter });
    }
  }, [language, view]);

  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;
    temp_movie_category["tempmovie_subcategory"] = form?.["movie_subcategory"];
    temp_movie_category["tempmovie_category"] = form?.["movie_category"];
    temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
    temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
    temp_movie_category["tempmovie_language"] = form?.["movie_language"];
    temp_movie_category["movie_subcategory"] = subcategories?.data
      .map(
        (option) =>
          form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
          option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_category"] = categories?.data
      .map(
        (option) =>
          form?.["movie_category"]?.includes(option.category_name) && option.id
      )
      .filter((e) => e);

    temp_movie_category["movie_cast"] = casts?.data
      .map(
        (option) =>
          form?.["movie_cast"]?.includes(option.cast_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_genre"] = genre?.data
      .map(
        (option) =>
          form?.["movie_genre"]?.includes(option.genre_title) && option.id
      )
      .filter((e) => e);

    temp_movie_category["movie_language"] = language?.data
      .map(
        (option) =>
          form?.["movie_language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);

    // temp_movie_category["advertise"] = JSON.stringify(advertise);
    temp_movie_category["created_by"] = user?.id;
    setForm({
      ...temp_movie_category,
    });

    Object.keys(form).map((key) => data.append(key, form?.[key]));

    formStructure.map((structure) =>
      structure.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );

    const temp = tableData;
    if (isEdit) {
      dispatch(movie_update(data));
    } else {
      dispatch(movie_create(data));
    }
    setFlag(true);
  };
  // console.log("meeeeeee", subcategories)

  useMemo(() => {
    if (message?.statuscode == 200 && flag) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setFormStructure([...tempFormStruct]);
      setForm({
        set_position: tableData.tableBody.length + 1,
        subtitles: "Inactive",
        audio_language: "Inactive",
        movie_is_for18Plus: "No",

        allow_download: "Yes",
        status: "Active",
      });
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_movie_list(data));
      }, 1000);
      navigate("/Movie/Movie", { state: { view: "view_all" } });
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["movie_subcategory"] = form?.["tempmovie_subcategory"];
      tempForm["movie_category"] = form?.["tempmovie_category"];
      tempForm["movie_cast"] = form?.["tempmovie_cast"];
      tempForm["movie_genre"] = form?.["tempmovie_genre"];
      tempForm["movie_language"] = form?.["tempmovie_language"];
      tempForm["movie_thumbnail"] = form?.["tempmovie_thumbnail"];
      tempForm["movie_poster"] = form?.["tempmovie_poster"];
      setForm({ ...tempForm });
    }
  }, [message]);
  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, movie_duration: hDisplay + mDisplay + sDisplay });
  };
  const videoTrailer = useRef(null);
  const handleLoadedMetadataTrailer = () => {
    const video = videoTrailer.current;
    // console.log(video, "videoss");
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({
      ...form,
      movie_trailer_duration: hDisplay + mDisplay + sDisplay,
    });
  };

  const handleClose = () => {
    setOpenAdError(false);
  };

  useEffect(() => {
    if (movies?.statuscode !== 200) {
      setOpen1(true);
    } else {
      setOpen1(false);
    }
  }, [movies]);

  return (
    <>
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.movieMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <video
        style={{ display: "none" }}
        id="myvid1"
        src={form?.movie_trailer}
        ref={videoTrailer}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadataTrailer}
      ></video>
      <EnlargedView
        open={openApproval}
        setOpen={setOpenApproval}
        content={content}
      />
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />

      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Movie/CreateMovie/"}
        view_all={"/Movie/Movie/"}
        form={form}
        access={rights?.["Movie"]?.["create"] == "true"}
        export_excel={
          <Export
            fileName={"Movies"}
            access={rights?.["Movie"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        import_excel={
          <Import
            access={rights?.["Movie"]?.["import_excel"] == "true"}
            isClubed={true}
            module_name="movie"
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          formTitleNew={formTitleNew}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Movie/EditMovie"}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
